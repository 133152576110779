<template>
  <div class="container-main-content merchant-inactive">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-2">
          Doanh nghiệp này đang tạm thời ngừng hoạt động
        </h4>
        <p class="f14 mb-4 text-second-color">
          Hãy liên hệ với quản lý hoặc chủ doanh nghiệp để được hỗ trợ.
        </p>
        <button type="button" class="btn btn-primary">
          ĐĂNG NHẬP TÀI KHOẢN KHÁC
        </button>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
